import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';

// import $                  from 'jquery';
import './Distributeplanningpage.css';

import Overview                from '../../allBlocks/Overview/Overview.js';
import Imgleafblock            from '../../allBlocks/Imgleafblock/Imgleafblock.js';
import Challenges              from '../../allBlocks/Challenges/Challenges.js';
import Commonulliblock         from '../../allBlocks/Commonulliblock/Commonulliblock.js';

export default class Distributeplanningpage extends Component {

     overviewData(){
        return [
            {
                overviewTitle       : "Supply Chain Distribution Planning and Optimization",
                overviewSubTitle    : "Overview",
                overviewimg         : "",
                overviewtext1       : "The world’s leading integrated energy companies with extensive refining, marketing and distribution operations enhance their competitiveness through integration of their business processes. A key objective would be to improve coordination between distribution planning and operational scheduling across the supply chain and adapt the responses to rapidly changing market conditions. A powerful supply chain planning tool should solve multi-commodity distribution problems regarding allocating resources – including transportation, raw materials, sales demand and processing facilities – to maximize profitability.",
                overviewtext2       : "Supply chain distribution planning and optimization allows users to meet their primary and secondary distribution goals by:",
                overviewtext3       : "Inventory management and scheduling delivers value by:",
                commonulliUl : [
                    {
                        ulData : "Reduced overall distribution system costs"
                    },
                    {
                        ulData : "Strategic and operational planning capabilities for product sourcing, distribution, sales and inventory"
                    },
                    {   
                        ulData : "Economically optimal distribution plans generated by a linear program"
                    },
                    {   
                        ulData : "Improved business strategies associated with inter-refinery exchanges, buy/sell contracts and import/export deals"
                    },
                    {   
                        ulData : "Integrated demand management, distribution scheduling, refinery planning and scheduling"
                    }
                ]

            } 
                        
        ]
    }

    Commonleafulblock(){
        return [
            {
                challengesTitle : "",
                challengestext  : "A supply chain distribution planning and optimization model should feature:",
                challengestext2 : "",
                commonulliUl : [
                    {
                        ulData : "Model the Distribution Problem: Optimizes the assets and economics of distributing multiple commodities using multiple modes of transportation over multiple time periods. At any node in the network, materials may be produced or purchased, sold or transported, exchanged, inventoried or consumed."
                    },
                    {
                        ulData : "Optimal Distribution Plans: Generates optimal feedstock and/or product distribution plans for multiple time periods at various levels of granularity (days, weeks, months, etc.)"
                    },
                    {   
                        ulData : "Early Detection of Supply/Demand Problems: Forecasts and proactively evaluates supply and demand problems before they occur."
                    },
                    {   
                        ulData : "Terminal Management: Increases asset utilization and rationalization of terminals and reduces average inventory level at terminals. “Right-sizes” inventory and increases margins by getting the right product to the right location at the right price, via the right transportation mode."
                    },
                    {   
                        ulData : "Exchange Management: Evaluates “make vs. buy vs. exchange” decisions and import/export alternatives. Multiple nodes and multiple products per exchange contract are supported. Enables the user to maximize leverage of exchange agreements which is very difficult to do with a spreadsheet."
                    },
                    {   
                        ulData : "Integration with Refinery Planning: Integrating the supply chain distribution optimization model with the refinery planning model streamlines the work process for refinery planning and distribution planning into a single step process and creates a true demand pull work process."
                    }
                ]
            }, 
                  
        ]
    }


  
  commonulliData(){
        return [
            {
                commonulliheading : "",
                commonullisubheading : "We, at iOG, have experience with different supply chain tools and can provide the following services related to this area:",
                commonullitext  : "",
                commonulliUl : [
                    {   ulData : "Consulting Services",
                        commonulliLi : [
                                            {
                                                liData : "What-if analysis for new strategies/markets, new terminals and transportation modes"
                                            },
                                            {
                                                liData : "Consult in distribution optimization based profits"
                                            }
                                        ]
                    },
                    {   ulData : "Implementation Services",
                        commonulliLi : [
                                            {
                                                liData : "Build supply chain distribution planner model"
                                            },
                                            {
                                                liData : "Integration of SCM model with Refinery Planning Models for efficient plan"
                                            },
                                            {
                                                liData : "Build customized reports depending upon the terminal, depots and inventories"
                                            },
                                            {
                                                liData : "Terminal optimization for new terminal setup"
                                            },
                                            {
                                                liData : "Implementation of Distribution Planning tools such as Aspen Petroleum Supply Chain Planner, Honeywell SAND"
                                            }
                                        ]
                    }
                ]
            }                        
        ]
    }

    imgleafblockData(){
        return [
            {
                plansTitle        : "",
                plansSubTitle1    : "",
                plansSubTitle2    : "",
                planstext         : "",
                planstext1        : "",
                planstext2        : "",
                planstext3        : "",
                plansimg          : "images/distributeplann.jpg",

            }
        ]
    }

   

  render() {  
    return (
      <div> 
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 iogimplmntbrdCrm refinerybrdCrm ">
       <div className=" col-lg-4 ">
         <div class="container">
          <div class="moduletable">           
           <ul class="breadcrumb breadcrumb2 demadnavigate">
            <li class="active">&nbsp;</li><li>
             <a href="/" class="pathway">Home</a>
              <span class="divider">
               <img src="/media/system/images/arrow.png" alt=""/></span></li>
               <li><a href="/services" class="pathway">Expertise</a>
              <span class="divider">
               <img src="/media/system/images/arrow.png" alt=""/></span></li>      
                <li class="active"><span>Distribution Planning</span></li></ul>
            </div>
          </div>
        </div> 
       </div>   
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 NOpadding">
      	<Overview OverviewData={this.overviewData()} />
        <Imgleafblock ImgleafblockData={this.imgleafblockData()} />
        <Challenges   CommonleafblockData={this.Commonleafulblock()} />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
        <Commonulliblock CommonulliData={this.commonulliData()} />
      </div>
     </div> 
    );  
  }
}

